import { Storage } from '@aws-amplify/storage';
import {
  AccountFieldsFragment,
  ResidentRequestScheduling,
  TaxDetail,
  UpdateAccountInput,
  useGetAccountQuery,
  useUpdateAccountMutation,
} from 'api';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useGetProvinceTaxDetails } from 'hooks/useGetProvinceTaxDetails';
import { useModalControl } from 'hooks/useModalControl';
import { useNotification } from 'hooks/useNotification';
import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_GST } from 'system';
import { addressShape } from 'system/shapes';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

export const accountFormFieldShape = z.object({
  name: z.string(),
  phone: z.string(),
  residentRequestScheduling: z.nativeEnum(ResidentRequestScheduling),
  address: addressShape.optional(),
  logo: z
    .custom<File>((v) => v instanceof File)
    .refine(
      (file) => file?.size ?? 0 <= 2 * 1024 * 1024,
      'The logo file size must be less than 2 MB'
    )
    .refine(
      (file) => !file || ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(file?.type),
      'Unsupported Format'
    )
    .optional(),
  logoPreview: z.string().optional(),
  logoKey: z.string().optional(),
});

export type AccountFormFields = z.infer<typeof accountFormFieldShape>;

enum Notifications {
  ACCOUNT_UPDATE = 'Your account has been successfully updated.',
  ACCOUNT_SETTINGS_UPDATE = 'Account settings have been successfully updated.',
}

export const useAccount = () => {
  const { sendNotification } = useNotification();
  const { data, loading, error: apolloError } = useGetAccountQuery();
  const { getTaxDetails } = useGetProvinceTaxDetails();
  const [account, setAccount] = useState<AccountFieldsFragment>();
  const [taxDetails, setTaxDetails] = useState<TaxDetail>();
  const [gstRate, setGstRate] = useState(DEFAULT_GST);
  const [gstName, setGstName] = useState('');
  const [updateAccountMutation, { error: apolloUpdateError }] = useUpdateAccountMutation();
  const [showModal, hideModal, modalOpen] = useModalControl();
  useEffect(() => setAccount(data?.account), [data]);
  useErrorNotifications(apolloError);
  useErrorNotifications(apolloUpdateError);

  const updateAccount = async ({
    name,
    phone,
    residentRequestScheduling,
    address,
    logo,
    logoKey,
  }: AccountFormFields) => {
    if (!account) {
      console.error('No account found');
      return;
    }

    const updatedLogoKey = logo ? `accounts/${account.id}/${uuid()}-${logo.name}` : (logoKey ?? '');
    logo &&
      (await Storage.put(updatedLogoKey, logo, {
        bucket: process.env.REACT_APP_PUBLIC_BUCKET,
        acl: 'public-read',
      }));

    const input: UpdateAccountInput = {
      name,
      phone,
      settings: {
        ...account.settings,
        resident_request_scheduling:
          residentRequestScheduling ?? ResidentRequestScheduling.Preferences,
      },
      address,
      logoKey: updatedLogoKey,
    };

    await updateAccountMutation({
      variables: {
        id: account.id,
        input,
      },
    });

    hideModal();
    sendNotification(Notifications.ACCOUNT_UPDATE, 'success');
  };

  const updateExpiryThreshold = async ({
    soonDaysThreshold,
    laterDaysThreshold,
  }: {
    soonDaysThreshold?: number;
    laterDaysThreshold?: number;
  }) => {
    if (!account) {
      console.error('No account found');
      return;
    }
    const input = {
      settings: {
        ...account.settings,
        soon_days_threshold: soonDaysThreshold,
        later_days_threshold: laterDaysThreshold,
      },
    };

    await updateAccountMutation({
      variables: {
        id: account.id,
        input,
      },
    });

    hideModal();
    sendNotification(Notifications.ACCOUNT_UPDATE, 'success');
  };

  const updateCampaignApplicationRules = async ({
    requiredDocs,
  }: {
    requiredDocs?: {
      quantity: number;
      docTypes: string[];
    }[];
  }) => {
    if (!account) {
      console.error('No account found');
      return;
    }
    const input = {
      settings: {
        ...account.settings,
        campaignApplicationRules: { requiredDocs },
      },
    };

    await updateAccountMutation({
      variables: {
        id: account.id,
        input,
      },
    });

    hideModal();
    sendNotification(Notifications.ACCOUNT_SETTINGS_UPDATE, 'success');
  };

  const getTaxDetailsByProvince = useCallback(async () => {
    const result = await getTaxDetails({ province: account?.address?.province });
    const gstInfo = result?.find((tax) => tax.gst);
    setTaxDetails(gstInfo);
  }, [account?.address?.province, getTaxDetails]);

  useEffect(() => {
    getTaxDetailsByProvince();
    if (taxDetails?.rate) {
      setGstRate(taxDetails.rate);
      setGstName(taxDetails.label);
    } else {
      setGstRate(DEFAULT_GST);
      setGstName('GST');
    }
  }, [account, getTaxDetailsByProvince, taxDetails]);

  return {
    loading,
    account,
    accountId: account?.id,
    accountName: account?.name,
    updateAccount,
    updateExpiryThreshold,
    updateCampaignApplicationRules,
    showModal,
    hideModal,
    modalOpen,
    gstRate,
    gstName,
  };
};
